import validate from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_l5m7jj2ixqqu74boqpfbot3v5a/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/auth.global.js";
import manifest_45route_45rule from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/node_modules/.pnpm/nuxt@3.12.1_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.61.0_eslint@8.57.0_f_l5m7jj2ixqqu74boqpfbot3v5a/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "company-guard": () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/middleware/companyGuard.js")
}