import { default as accountFe4Epdul0sMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue?macro=true";
import { default as indexnjXLRCUJozMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue?macro=true";
import { default as blockchain_45wallet1K0Ej978OPMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue?macro=true";
import { default as dedicated_45addresssriOkMqfzrMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue?macro=true";
import { default as optionsIcRCeN5LeHMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue?macro=true";
import { default as indexCyeIgPpcgNMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue?macro=true";
import { default as binanceatXHDwbVwdMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue?macro=true";
import { default as _91network_93SZOuH7N4u1Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue?macro=true";
import { default as index5b8AzbbJNRMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue?macro=true";
import { default as brlaBDfM4nnCOTMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue?macro=true";
import { default as coinbase_walletqNKEvJllj2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue?macro=true";
import { default as coinbase9JhBX0Pi8SMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue?macro=true";
import { default as eurLnQ61iru8YMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue?macro=true";
import { default as forexk5lFfPiyDjMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue?macro=true";
import { default as gbpHLg1FPT6flMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue?macro=true";
import { default as international_bankQsroKAP9caMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue?macro=true";
import { default as internationalZlPQXp97ZkMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue?macro=true";
import { default as krakenlqYXy6yE49Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue?macro=true";
import { default as ngnGNqE7YzCuUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue?macro=true";
import { default as us5HhkvegmHxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue?macro=true";
import { default as optionsLhAAxDBF72Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue?macro=true";
import { default as rampsN20zPDaJmvMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue?macro=true";
import { default as coinbase5X7ptUD19sMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue?macro=true";
import { default as verify_45walletgEsRoovE2iMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue?macro=true";
import { default as _91uuid_93JEaiPM3UFgMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue?macro=true";
import { default as _91token_93sqjE0UC3d9Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue?macro=true";
import { default as login9Qn5Zdd6xxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue?macro=true";
import { default as registerhUljTAMpbkMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue?macro=true";
import { default as blocked7n8MLNUIbAMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue?macro=true";
import { default as classificationXSXSIpOeX8Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue?macro=true";
import { default as indexjRz6bVsVYCMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue?macro=true";
import { default as indexfbG7HTbX2IMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue?macro=true";
import { default as dashboardLIlumG7rxVMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue?macro=true";
import { default as developerFUCoAk7TwuMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue?macro=true";
import { default as pay_schedulevS2qqEJSUpMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue?macro=true";
import { default as indexgZiPxk8E4QMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue?macro=true";
import { default as _91accountId_932p8ouGNxFoMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue?macro=true";
import { default as deposit_45usITM04m3W4MMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue?macro=true";
import { default as depositTRt3MDbPoyMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue?macro=true";
import { default as _91accountId_93NgnsUFyUdKMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue?macro=true";
import { default as riseidFjvJuBUyw6Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue?macro=true";
import { default as fundDE7xpkkkm0Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue?macro=true";
import { default as indexRQQ7sdorr2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue?macro=true";
import { default as _91code_93uwkqidlux6Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue?macro=true";
import { default as _91id_93bIsf7EU6AbMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue?macro=true";
import { default as indexrhSwviVcgnMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue?macro=true";
import { default as index4GamHCFMMVMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue?macro=true";
import { default as jwtNXj1Hr122zMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue?macro=true";
import { default as indexBQyz5E0DWrMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue?macro=true";
import { default as teamtEYi41AyjXMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue?macro=true";
import { default as index2aidM0PSUdMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue?macro=true";
import { default as onboardingo3U8jSIXZ1Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue?macro=true";
import { default as hourlypobwk8vdcqMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue?macro=true";
import { default as instantgDnn0VsORhMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue?macro=true";
import { default as milestoneEYfBV0hyHZMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue?macro=true";
import { default as onceRM4KhUmvD7Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue?macro=true";
import { default as recurringFHBkvL5F6nMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue?macro=true";
import { default as _91userId_93FkLSU0E2iWMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue?macro=true";
import { default as csvZWAWE9HdwEMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue?macro=true";
import { default as indexA55vKABD7CMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue?macro=true";
import { default as indexCxOL7u5rxGMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue?macro=true";
import { default as managementyALnrGcnWFMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue?macro=true";
import { default as _91id_93BjsJycgx9WMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue?macro=true";
import { default as _91id_93YEk4xMJDSlMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue?macro=true";
import { default as _91psId_93uNj0a9fFTZMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue?macro=true";
import { default as indexfiQwM23cW1Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue?macro=true";
import { default as transaction_45historytLO0G0GLMAMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue?macro=true";
import { default as playgroundR1uHNljGLZMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue?macro=true";
import { default as businessHw3UcffGrtMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue?macro=true";
import { default as indexkwWYaL90plMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue?macro=true";
import { default as referralCVzDcIa8OMMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue?macro=true";
import { default as riseid_45erc20_45withdrawVAN7QvluyCMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue?macro=true";
import { default as riseidEW5rOSpzEXMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue?macro=true";
import { default as securitySZlcgXk7cOMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue?macro=true";
import { default as sign_45document48YpqXYriKMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue?macro=true";
import { default as _2fa54BtIAgu4NMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue?macro=true";
import { default as callbackBSaDwxl98BMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue?macro=true";
import { default as codekFpgzzlZAWMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue?macro=true";
import { default as indexe6aTtS0U3gMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue?macro=true";
import { default as sign_45outEMEQQT4gHxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue?macro=true";
import { default as codeLUewZCYvH7Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue?macro=true";
import { default as indexU8xBld3iEGMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue?macro=true";
import { default as waitingEK1m7VZspxMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue?macro=true";
import { default as index6rLtdBOaXLMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue?macro=true";
import { default as optionsfTa2S86MotMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue?macro=true";
import { default as indexUMHZuFthqPMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue?macro=true";
import { default as import4t3slRaWB2Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue?macro=true";
import { default as indexhOwtrCXkq0Meta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue?macro=true";
import { default as batch_warmed_lightb1I24QUevaMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue?macro=true";
import { default as batch_warmed7YbCs69PnUMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue?macro=true";
import { default as direct3PjZju3ayPMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue?macro=true";
import { default as sumsub5t3NhAwRQbMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue?macro=true";
import { default as warmed_lightr1b56NmTKDMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue?macro=true";
import { default as warmediBlhVYLGxLMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue?macro=true";
import { default as invitevdDB2f4XHlMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue?macro=true";
import { default as indexEXPwSbDHKyMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue?macro=true";
import { default as _91accountId_93PN8pFxGjcEMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue?macro=true";
import { default as _91accountId_93LJczrAvJcEMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue?macro=true";
import { default as withdrawprIVsTB83KMeta } from "/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountFe4Epdul0sMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding",
    path: "/accounts/add-funding",
    meta: indexnjXLRCUJozMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options",
    path: "/accounts/add-funding/options",
    meta: optionsIcRCeN5LeHMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-funding-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/blockchain-wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-funding-options-dedicated-address",
    path: "dedicated-address",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-funding/options/dedicated-address.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw",
    path: "/accounts/add-withdraw",
    meta: indexCyeIgPpcgNMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options",
    path: "/accounts/add-withdraw/options",
    meta: optionsLhAAxDBF72Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options.vue").then(m => m.default || m),
    children: [
  {
    name: "accounts-add-withdraw-options-binance",
    path: "binance",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/binance.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet-network",
    path: "blockchain-wallet/:network()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/[network].vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-blockchain-wallet",
    path: "blockchain-wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/blockchain-wallet/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-brla",
    path: "brla",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/brla.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase_wallet",
    path: "coinbase_wallet",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase_wallet.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-coinbase",
    path: "coinbase",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-eur",
    path: "eur",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/eur.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-forex",
    path: "forex",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/forex.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-gbp",
    path: "gbp",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/gbp.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international_bank",
    path: "international_bank",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international_bank.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-international",
    path: "international",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/international.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-kraken",
    path: "kraken",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/kraken.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-ngn",
    path: "ngn",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/ngn.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-withdraw-options-us",
    path: "us",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/options/us.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "accounts-add-withdraw-ramps",
    path: "/accounts/add-withdraw/ramps",
    meta: rampsN20zPDaJmvMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add-withdraw/ramps.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-coinbase",
    path: "/accounts/add/callback/coinbase",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/coinbase.vue").then(m => m.default || m)
  },
  {
    name: "accounts-add-callback-verify-wallet",
    path: "/accounts/add/callback/verify-wallet",
    meta: verify_45walletgEsRoovE2iMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/accounts/add/callback/verify-wallet.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-uuid",
    path: "/auth/invite/:uuid()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "auth-invite-b2b-token",
    path: "/auth/invite/b2b/:token()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/invite/b2b/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "blocked",
    path: "/blocked",
    meta: blocked7n8MLNUIbAMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/blocked.vue").then(m => m.default || m)
  },
  {
    name: "clients-id-classification",
    path: "/clients/:id()/classification",
    meta: classificationXSXSIpOeX8Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/classification.vue").then(m => m.default || m)
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexjRz6bVsVYCMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "clients",
    path: "/clients",
    meta: indexfbG7HTbX2IMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/clients/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardLIlumG7rxVMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "developer",
    path: "/developer",
    meta: developerFUCoAk7TwuMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/developer.vue").then(m => m.default || m)
  },
  {
    name: "documents-payschedule-pay_schedule",
    path: "/documents/:payschedule()/pay_schedule",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/[payschedule]/pay_schedule.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexgZiPxk8E4QMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: "fund",
    path: "/fund",
    meta: fundDE7xpkkkm0Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund.vue").then(m => m.default || m),
    children: [
  {
    name: "fund-dedicated-address-accountId",
    path: "dedicated-address/:accountId()",
    meta: _91accountId_932p8ouGNxFoMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/dedicated-address/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit-us",
    path: "deposit-us",
    meta: deposit_45usITM04m3W4MMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit-us.vue").then(m => m.default || m)
  },
  {
    name: "fund-deposit",
    path: "deposit",
    meta: depositTRt3MDbPoyMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/deposit.vue").then(m => m.default || m)
  },
  {
    name: "fund-external-account-accountId",
    path: "external-account/:accountId()",
    meta: _91accountId_93NgnsUFyUdKMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/external-account/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "fund-riseid",
    path: "riseid",
    meta: riseidFjvJuBUyw6Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/fund/riseid.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexRQQ7sdorr2Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-code",
    path: "/invite/:code()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invite/[code].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add-id",
    path: "/invoices/add/:id()",
    meta: _91id_93bIsf7EU6AbMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/[id].vue").then(m => m.default || m)
  },
  {
    name: "invoices-add",
    path: "/invoices/add",
    meta: indexrhSwviVcgnMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/add/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: index4GamHCFMMVMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "jwt",
    path: "/jwt",
    meta: jwtNXj1Hr122zMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/jwt.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create",
    path: "/management/company/create",
    meta: indexBQyz5E0DWrMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/index.vue").then(m => m.default || m)
  },
  {
    name: "management-company-create-team",
    path: "/management/company/create/team",
    meta: teamtEYi41AyjXMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/create/team.vue").then(m => m.default || m)
  },
  {
    name: "management-company",
    path: "/management/company",
    meta: index2aidM0PSUdMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/management/company/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingo3U8jSIXZ1Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId",
    path: "/payments/:userId()",
    meta: _91userId_93FkLSU0E2iWMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId].vue").then(m => m.default || m),
    children: [
  {
    name: "payments-userId-hourly",
    path: "hourly",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/hourly.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-instant",
    path: "instant",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/instant.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-milestone",
    path: "milestone",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/milestone.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-once",
    path: "once",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/once.vue").then(m => m.default || m)
  },
  {
    name: "payments-userId-recurring",
    path: "recurring",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/[userId]/recurring.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "payments-batch-csv",
    path: "/payments/batch/csv",
    meta: csvZWAWE9HdwEMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/csv.vue").then(m => m.default || m)
  },
  {
    name: "payments-batch",
    path: "/payments/batch",
    meta: indexA55vKABD7CMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/batch/index.vue").then(m => m.default || m)
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexCxOL7u5rxGMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-management",
    path: "/payments/management",
    meta: managementyALnrGcnWFMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/management.vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-fund-id",
    path: "/payments/receipts/fund/:id()",
    meta: _91id_93BjsJycgx9WMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/fund/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-receipts-payment-id",
    path: "/payments/receipts/payment/:id()",
    meta: _91id_93YEk4xMJDSlMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/receipts/payment/[id].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets-psId",
    path: "/payments/timesheets/:psId()",
    meta: _91psId_93uNj0a9fFTZMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/[psId].vue").then(m => m.default || m)
  },
  {
    name: "payments-timesheets",
    path: "/payments/timesheets",
    meta: indexfiQwM23cW1Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "payments-transaction-history",
    path: "/payments/transaction-history",
    meta: transaction_45historytLO0G0GLMAMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/payments/transaction-history.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "profile-business",
    path: "/profile/business",
    meta: businessHw3UcffGrtMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/business.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexkwWYaL90plMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referralCVzDcIa8OMMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "riseid-erc20-withdraw",
    path: "/riseid-erc20-withdraw",
    meta: riseid_45erc20_45withdrawVAN7QvluyCMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid-erc20-withdraw.vue").then(m => m.default || m)
  },
  {
    name: "riseid",
    path: "/riseid",
    meta: riseidEW5rOSpzEXMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/riseid.vue").then(m => m.default || m)
  },
  {
    name: "security",
    path: "/security",
    meta: securitySZlcgXk7cOMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "sign-document",
    path: "/sign-document",
    meta: sign_45document48YpqXYriKMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-document.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-2fa",
    path: "/sign-in/2fa",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/2fa.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-callback",
    path: "/sign-in/callback",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/callback.vue").then(m => m.default || m)
  },
  {
    name: "sign-in-code",
    path: "/sign-in/code",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-in/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outEMEQQT4gHxMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-out.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-code",
    path: "/sign-up/code",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/code.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up-waiting",
    path: "/sign-up/waiting",
    meta: waitingEK1m7VZspxMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/sign-up/waiting.vue").then(m => m.default || m)
  },
  {
    name: "subscription",
    path: "/subscription",
    meta: index6rLtdBOaXLMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/index.vue").then(m => m.default || m)
  },
  {
    name: "subscription-options",
    path: "/subscription/options",
    meta: optionsfTa2S86MotMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/subscription/options.vue").then(m => m.default || m)
  },
  {
    name: "talent-id",
    path: "/talent/:id()",
    meta: indexUMHZuFthqPMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-import",
    path: "/talent/import",
    meta: import4t3slRaWB2Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/import.vue").then(m => m.default || m)
  },
  {
    name: "talent",
    path: "/talent",
    meta: indexhOwtrCXkq0Meta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/index.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite",
    path: "/talent/invite",
    meta: invitevdDB2f4XHlMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite.vue").then(m => m.default || m),
    children: [
  {
    name: "talent-invite-batch_warmed_light",
    path: "batch_warmed_light",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-batch_warmed",
    path: "batch_warmed",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/batch_warmed.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-direct",
    path: "direct",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/direct.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-sumsub",
    path: "sumsub",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/sumsub.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed_light",
    path: "warmed_light",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed_light.vue").then(m => m.default || m)
  },
  {
    name: "talent-invite-warmed",
    path: "warmed",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/talent/invite/warmed.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tasks",
    path: "/tasks",
    meta: indexEXPwSbDHKyMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/tasks/index.vue").then(m => m.default || m)
  },
  {
    name: "withdraw",
    path: "/withdraw",
    meta: withdrawprIVsTB83KMeta || {},
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw.vue").then(m => m.default || m),
    children: [
  {
    name: "withdraw-blockchain-accountId",
    path: "blockchain/:accountId()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/blockchain/[accountId].vue").then(m => m.default || m)
  },
  {
    name: "withdraw-wire-accountId",
    path: "wire/:accountId()",
    component: () => import("/data/runners/eight/_work/apps-monorepo/apps-monorepo/apps/pay/pages/withdraw/wire/[accountId].vue").then(m => m.default || m)
  }
]
  }
]